import React, { ReactElement, useEffect, useState } from 'react';
import { AssetV2 } from 'types/asset.type';
import { FilterSetup, Filters } from 'types/filter.type';
import { FetchPayload, SortDirection, SortField } from 'components/asset-management/types/asset-management.service.type';
import Translation from 'components/data/Translation';
import AssetManagementSelectorFilterBar from './filter-bar';
import AssetManagementSelectorAssetsView from './assets-view';
import AssetManagementSelectorTabs from './tabs';

import '../styles/assets.scss';

interface Props {
    assetsList: AssetV2<unknown, unknown>[] | null;
    categories: string[] | null;
    addedContent?: ReactElement;
    hideFilters: string[];
    hasMore: boolean;
    filters: Filters;
    filterSetup: FilterSetup;
    sortField?: SortField;
    sortDirection?: SortDirection;
    loading: boolean;
    selectedItemLoading: boolean;
    assetTile: ReactElement;
    onChangeFilter: (filter: string, value: string | string[]) => void;
    onClearFilter: () => void;
    onSelect: (item: AssetV2<unknown, unknown>) => void;
    onGetAssetsContent: (refresh: boolean, payload?: FetchPayload) => void;
}

const AssetManagementSelectorSelectorAssets = ({
    assetsList,
    categories,
    addedContent,
    hideFilters,
    hasMore,
    filterSetup,
    filters,
    sortField,
    sortDirection,
    loading,
    selectedItemLoading,
    assetTile,
    onGetAssetsContent,
    onChangeFilter,
    onClearFilter,
    onSelect
}: Props) => {
    const [forceSearchTerm, setForceSearchTerm] = useState<string>('');

    useEffect(() => {
        if (filters.query && filters.query.length) {
            setForceSearchTerm(filters.query as string);
        }
    }, []);

    const sort = (sortField: SortField, sortDirection: SortDirection) => {
        onGetAssetsContent(true, { sorting: { sortField, sortDirection } });
    };

    return (
        <div className="asset-management-selector-assets">
            <AssetManagementSelectorFilterBar
                filterSetup={filterSetup}
                filters={filters}
                hideFilters={hideFilters}
                sortField={sortField}
                sortDirection={sortDirection}
                forceSearchTerm={forceSearchTerm}
                onChangeFilter={onChangeFilter}
                onClearFilter={onClearFilter}
                onSort={sort}
            />
            {categories && categories.length > 0 && (
                <AssetManagementSelectorTabs
                    tabs={[
                        { label: Translation.get('labels.all', 'common'), value: '_all' },
                        ...categories.map((category) => ({ label: category, value: category }))
                    ]}
                    activeTab={filters.categories?.[0] || '_all'}
                    className="asset-management-selector-assets__categories"
                    onChange={(value) => onChangeFilter('categories', value === '_all' ? [] : [value])}
                />
            )}
            {addedContent && addedContent}
            <AssetManagementSelectorAssetsView
                assetsList={assetsList}
                hidePlaceholder={Boolean(addedContent)}
                hasMore={hasMore}
                loading={loading}
                selectedItemLoading={selectedItemLoading}
                assetTile={assetTile}
                onSelect={onSelect}
                onGetContent={onGetAssetsContent}
            />
        </div>
    );
};

export default AssetManagementSelectorSelectorAssets;
