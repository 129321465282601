export const MULTIS = {
    brands: 'multiBrand',
    departments: 'multiDepartment',
    markets: 'multiMarket'
};

// Input keys for which the model is set at the root of the asset All others are set in 'labels'.
export const ASSET_ROOT_INPUTS = [
    'title',
    'createdBy',
    'createdAt',
    'data',
    'customData',
    'labels',
    'lastModifiedAt',
    'lastModifiedBy',
    'public',
    'reviewState',
    'status',
    'validFrom',
    'validTo',
    'collections',
    'subType'
];

// Input keys for which the model is set at the root of the collection. All others are set in 'labels'.
export const COLLECTION_ROOT_INPUTS = ['title', 'parentId', 'createdBy', 'createdAt', 'updatedBy', 'updatedAt', 'assetCount', 'public', 'labels', 'thumbnails'];

// The key used for the collections field and filter.
export const COLLECTIONS_KEY = 'collections';

// Used to assign an id to the root of the tree.
export const COLLECTION_TREE_ROOT_ID = '_all-collections-root';

// Asset fields that are generated by Google vision.
export const SPECIAL_FIELDS_GOOGLE_VISION = ['dominant_colors', 'vision_tags'];

// Labels we do not want to show automatically with the other labels in asset info
export const SPECIAL_FIELDS_NO_SHOW = ['dominant_colors'];

// Fields that can be tied to a user or campaign.
export const SPECIAL_FIELDS = { market: 'markets', brand: 'brands', department: 'departments' };
