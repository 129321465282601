import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AssetV2 } from 'types/asset.type';
import Icon from '@mui/material/Icon';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import { useAMFetch, useAMData } from 'components/asset-management/hooks';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import useAMAssetSelection from 'components/asset-management/hooks/useAMAssetSelection';

interface Props {
    asset: AssetV2<unknown, unknown>;
    onClose: () => void;
    onSetWaiting: (waiting: boolean) => void;
}

const AssetManagementAssetMenuItemsRestoreToDraft = ({ asset, onClose, onSetWaiting }: Props) => {
    const { userHasRight } = useAssetManagementConfigContext();
    const { setSelectedAssets } = useAMAssetSelection();
    const { refreshLists } = useAMFetch();
    const { data } = useAMData<{ selectedAssetIds: string[] }>({
        selectedAssetIds: 'state.selectedAssetIds'
    });
    const { selectedAssetIds } = data;

    const restoreToDraft = async (asset: AssetV2<unknown, unknown>) => {
        onSetWaiting(true);
        const newAsset: AssetV2<unknown, unknown> = { ...asset, status: 'draft' };
        const response = await AssetManagementService.patchAsset(newAsset, { updateOnlyMetadata: true });
        if (response) {
            setSelectedAssets(selectedAssetIds.filter((id: string) => id !== asset._id));
            await refreshLists('binned');
            await refreshLists('draft');
            onSetWaiting(false);
        }
    };

    if (!userHasRight('write')) return null;
    if (asset.status !== 'binned') return null;

    return (
        <MenuItem
            onClick={(e) => {
                e.stopPropagation();
                restoreToDraft(asset);
                onClose();
            }}
            data-cy="templateManagement-restoreDraft-button">
            <ListItemIcon>
                <Icon>edit_note</Icon>
            </ListItemIcon>
            <ListItemText>{Translation.get('actions.restore', 'asset-management')}</ListItemText>
        </MenuItem>
    );
};

export default AssetManagementAssetMenuItemsRestoreToDraft;
