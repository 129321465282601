import axios, { InternalAxiosRequestConfig, AxiosInstance } from 'axios';
import User from 'components/data/User';

/**
 * Set initial request data
 */
const AssetManagementRequest: AxiosInstance = axios.create({
    baseURL: process.env.ASSET_MANAGEMENT_URL,
    timeout: Number(process.env.APP_API_TIMOUT),
    withCredentials: true
});

/**
 * Intercept requests
 * 1.   Add auth information as default to request, will be overridden if is set
 *      in the request data.
 */
AssetManagementRequest.interceptors.request.use(
    (config: InternalAxiosRequestConfig<unknown>): InternalAxiosRequestConfig<unknown> => {
        try {
            const JWTtoken: string = (() => {
                if (process.env.EXTERNAL_PREVIEW) {
                    // For the external preview, we use the preview token.
                    return User.get('previewToken');
                }
                return User.get('apiToken');
            })();

            if (JWTtoken) config.headers.Authorization = `Bearer ${JWTtoken}`;
            return config;
        } catch (e) {
            return config;
        }
    },

    (error) => {
        return Promise.reject(error);
    }
);

export { AssetManagementRequest };
export default AssetManagementRequest;
