import React, { useRef, cloneElement, ReactElement, useMemo } from 'react';
import classNames from 'classnames';
import { AssetV2 } from 'types/asset.type';
import Loader from 'components/ui-components/Loader';
import { mapAssetToSelectorItem } from 'components/asset-management/utilities';
import Illustration from 'components/ui-components-cape/Illustration';
import EmptyState from 'components/ui-components-cape/EmptyState';
import { SelectorGridItemSourceData } from 'components/assets/AssetGalleryDialog/components/content/sidebar/components/selectors/grid/components/selector-grid-item';
import Translation from 'components/data/Translation';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';
import GridWrapper from 'components/ui-components/GridWrapper';
import { Memoize } from 'components/template-designer/components/ui-components/memoize/memoize';
import ColumnWrapper from 'components/ui-components/ColumnWrapper';
import AssetManagementInfiniteScroll from '../../infinite-scroll';

import '../styles/assets-view.scss';

interface Props {
    assetsList: AssetV2<unknown, unknown>[] | null;
    hasMore: boolean;
    hidePlaceholder?: boolean;
    loading: boolean;
    selectedItemLoading: boolean;
    assetTile: ReactElement;
    onSelect: (item: AssetV2<unknown, unknown>) => void;
    onGetContent: (refresh: boolean) => void;
}

const AssetManagementSelectorAssetsView = ({
    assetsList,
    hasMore,
    loading,
    selectedItemLoading,
    hidePlaceholder,
    assetTile,
    onSelect,
    onGetContent
}: Props) => {
    const { gridType } = useAssetManagementConfigContext();
    const scrollParentRef = useRef<HTMLDivElement>(null);

    const itemsList = useMemo(() => {
        if (!assetsList) return null;
        const list = assetsList.map((asset) => mapAssetToSelectorItem(asset)).filter((item) => item !== null) as SelectorGridItemSourceData[];
        return list;
    }, [assetsList]);

    const loadMore = () => {
        onGetContent(false);
    };

    return (
        <div
            className={classNames('asset-management-selector-assets-view', {
                'asset-management-selector-assets-view--empty': itemsList && itemsList.length === 0
            })}
            ref={scrollParentRef}>
            <Loader isLoading={selectedItemLoading}>
                {itemsList && itemsList.length > 0 && (
                    <Memoize valuesToCheck={itemsList}>
                        <AssetManagementInfiniteScroll container={scrollParentRef.current} loadMore={loadMore} loading={loading} hasMore={hasMore}>
                            {gridType === 'grid' ? (
                                <GridWrapper targetSize={220}>{itemsList.map((item) => cloneElement(assetTile, { key: item.id, item, onSelect }))}</GridWrapper>
                            ) : (
                                <ColumnWrapper targetSize={180}>
                                    {itemsList.map((item) => cloneElement(assetTile, { key: item.id, item, onSelect }))}
                                </ColumnWrapper>
                            )}
                        </AssetManagementInfiniteScroll>
                    </Memoize>
                )}
                {!hidePlaceholder && itemsList && itemsList.length === 0 && (
                    <EmptyState
                        primaryText={Translation.get('feedback.emptyState.noResults', 'common')}
                        illustration={<Illustration illustration="no-result" color="disabled" />}
                    />
                )}
            </Loader>
        </div>
    );
};

export default AssetManagementSelectorAssetsView;
