import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AssetV2 } from 'types/asset.type';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import { useAMAssetSelection, useAMData, useAMFetch, useAMNavigation } from 'components/asset-management/hooks';
import AssetManagementService from 'components/asset-management/services/asset-management.service';
import Icon from 'components/ui-components-v2/Icon';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';

interface Props {
    asset: AssetV2<unknown, unknown>;
    onClose: () => void;
    onSetWaiting: (waiting: boolean) => void;
}

const AssetManagementAssetMenuItemsRemoveFromCollection = ({ asset, onClose, onSetWaiting }: Props) => {
    const { collectionId } = useAMNavigation();
    const { refreshLists } = useAMFetch();
    const { setSelectedAssets } = useAMAssetSelection();
    const { userHasRight } = useAssetManagementConfigContext();
    const { data } = useAMData<{ selectedAssetIds: string[] }>({
        selectedAssetIds: 'state.selectedAssetIds'
    });
    const { selectedAssetIds } = data;

    if (!userHasRight('management')) return null;
    if (!collectionId) return null;

    const removeFromCollection = async () => {
        onSetWaiting(true);

        const newCollections = asset.collections?.filter((collection) => collection !== collectionId) || [];
        const newAsset: AssetV2<unknown, unknown> = { ...asset, collections: newCollections };
        const response = await AssetManagementService.patchAsset(newAsset, { updateOnlyMetadata: true });
        if (response) {
            setSelectedAssets(selectedAssetIds.filter((id: string) => id !== asset._id));
            await refreshLists('available');
            onSetWaiting(false);
        }
        onClose();
    };

    return (
        <MenuItem
            onClick={(e) => {
                e.stopPropagation();
                removeFromCollection();
            }}
            data-cy="templateManagement-removeFromCollection-button">
            <ListItemIcon>
                <Icon>remove</Icon>
            </ListItemIcon>
            <ListItemText>{Translation.get('actions.removeFromCollection', 'asset-management')}</ListItemText>
        </MenuItem>
    );
};

export default AssetManagementAssetMenuItemsRemoveFromCollection;
