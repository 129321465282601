import React, { useEffect } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { AssetV2 } from 'types/asset.type';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import MenuItem from 'components/ui-components-v2/MenuItem';
import Translation from 'components/data/Translation';
import { useAMAssetsNotValidated, useAMNavigation, useAMRelease } from 'components/asset-management/hooks';
import { useAssetManagementConfigContext } from 'components/asset-management/context/asset-management-config.context';

interface Props {
    asset: AssetV2<unknown, unknown>;
    onClose: () => void;
    onSetWaiting: (waiting: boolean) => void;
}

const AssetManagementAssetMenuItemsSetAvailable = ({ asset, onClose, onSetWaiting }: Props) => {
    const { userHasRight, versioning } = useAssetManagementConfigContext();
    const { statusInView } = useAMNavigation();
    const { handleReleaseClick, waiting } = useAMRelease(asset);
    const assetsNotValidated = useAMAssetsNotValidated([asset], statusInView);

    useEffect(() => {
        onSetWaiting(waiting);
    }, [waiting]);

    if (!userHasRight('management')) return null;
    if (asset.status !== 'draft') return null;

    const disabled = Object.keys(assetsNotValidated).length > 0;

    return (
        <Tooltip title={disabled ? Translation.get('validation.allRequiredFields', 'common') : ''}>
            <span>
                <MenuItem
                    disabled={disabled}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleReleaseClick();
                        onClose();
                    }}
                    data-cy="assetManagement-setAvailable-button">
                    <ListItemIcon>
                        <Icon>check_circle_outline</Icon>
                    </ListItemIcon>
                    <ListItemText>
                        {versioning
                            ? Translation.get('actions.setAvailableVersioning', 'asset-management')
                            : Translation.get('actions.setAvailable', 'asset-management')}
                    </ListItemText>
                </MenuItem>
            </span>
        </Tooltip>
    );
};

export default AssetManagementAssetMenuItemsSetAvailable;
