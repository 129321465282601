import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Menu from 'components/ui-components-v2/Menu';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import ToolIconButton from 'components/ui-components/ToolIconButton';
import Translation from 'components/data/Translation';
import AssetManagementCompactButton from './actions/compact-button';
import StopPropagationWrapper from './stop-propagation-wrapper';

export type AssetManagerMenuButtonType = 'compact' | 'toolIcon';

interface Props {
    children: React.ReactNode;
    buttonType?: AssetManagerMenuButtonType;
    waiting?: boolean;
    anchorEl: HTMLElement | undefined;
    open: boolean;
    className?: string;
    onSetAnchorEl: (anchorEl: HTMLElement | undefined) => void;
}

const AssetManagementMenuItemsWrapper = ({ children, buttonType, open, className, anchorEl, waiting = false, onSetAnchorEl }: Props) => {
    return (
        <StopPropagationWrapper>
            <Tooltip title={Translation.get('labels.moreActions', 'common')}>
                <span>
                    {buttonType === 'compact' && (
                        <AssetManagementCompactButton
                            onClick={(e) => {
                                if (e) {
                                    e.stopPropagation();
                                    onSetAnchorEl(e.currentTarget);
                                }
                            }}
                            data-cy="assetManagement-more-button"
                            variant="contained"
                            color="secondary"
                            disabled={waiting}
                            className={className}>
                            {waiting ? <CircularProgress size={24} /> : <Icon>more_horiz</Icon>}
                        </AssetManagementCompactButton>
                    )}

                    {buttonType === 'toolIcon' && (
                        <ToolIconButton
                            onClick={(e) => {
                                if (e) {
                                    e.stopPropagation();
                                    onSetAnchorEl(e.currentTarget);
                                }
                            }}
                            data-cy="assetManagement-more-button"
                            disabled={waiting}
                            className={className}>
                            {waiting ? <CircularProgress size={24} /> : <Icon>more_horiz</Icon>}
                        </ToolIconButton>
                    )}

                    {!buttonType && (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                onSetAnchorEl(e.currentTarget);
                            }}
                            size="small"
                            data-cy="assetManagement-more-button"
                            style={{ opacity: waiting ? 1 : undefined }}
                            disabled={waiting}
                            className={className}>
                            {waiting ? <CircularProgress size={24} /> : <Icon>more_horiz</Icon>}
                        </IconButton>
                    )}
                </span>
            </Tooltip>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={(e: { stopPropagation: () => void }) => {
                    e.stopPropagation();
                    onSetAnchorEl(undefined);
                }}>
                {children}
            </Menu>
        </StopPropagationWrapper>
    );
};

export default AssetManagementMenuItemsWrapper;
