import React, { useState } from 'react';
import { Collection } from 'types/collection.type';
import AssetManagementCollectionTileDisplay from './display';
import { useAMCollectionSelection, useAMData, useAMNavigation } from '../../../hooks';

import '../styles/main.scss';
import AMCheckbox from '../../am-checkbox';

interface Props {
    collection: Collection;
    collectionTileThumbnails?: React.ReactElement;
}

const AssetManagementCollectionTile = ({ collection, collectionTileThumbnails }: Props) => {
    const [hovered, setHovered] = useState<boolean>(false);
    const { openCollection } = useAMNavigation();
    const { selectCollection } = useAMCollectionSelection(collection.id);
    const {
        data: { selectedCollectionIds }
    } = useAMData<{ selectedCollectionIds: string[] }>({
        selectedCollectionIds: 'state.selectedCollectionIds'
    });

    const onTileClick = () => {
        if (selectedCollectionIds.length) {
            selectCollection(collection.id);
            return;
        }

        openCollection(collection.id);
    };

    return (
        <AssetManagementCollectionTileDisplay
            collection={collection}
            collectionTileThumbnails={collectionTileThumbnails}
            hovered={hovered}
            className="asset-management-selectable-asset"
            onHandleClick={onTileClick}
            onSetHovered={setHovered}>
            <AMCheckbox collection={collection} className="asset-management-collection-tile__checkbox" />
        </AssetManagementCollectionTileDisplay>
    );
};

export default AssetManagementCollectionTile;
